import { LitElement, html, css } from 'lit-element';

class AHrefConfirm extends LitElement {
  static get styles() {
    return css`
      a {
        color: var(--sl-color-primary-600);
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      m-icon {
        font-size: 0.8em; /* Réduire la taille de la police de l'icône */
        vertical-align: middle;
        line-height:8px;
      }

      .flex {
        display:flex;
        justify-content:space-between;
      }

      .normal {
        text-align:left;
      }

    `
  }

  static get properties() {
    return {
      href: { type: String },
      title: { type: String, reflect: true },
      target: { type: String, reflect: true },
      showConfirm: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.href = '';
    this.title = '';
    this.target = '';
    this.showConfirm = false;
  }

  navigate(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showConfirm = true;
  }

  closeDialog() {
    this.showConfirm = false;
  }

  openLink() {
    this.showConfirm = false;
    window.open(this.href, this.target);
  }

  render() {
    return html`
      <a href="${this.href}" @click="${this.navigate}" aria-label="${this.title}" >
        <slot></slot>
        <m-icon name="open_in_new"></m-icon>
      </a>
      <modal-dialog label="Lien extérieur" ?open="${this.showConfirm}">
        <div class="normal">
          Vous êtes sur le point d'ouvrir un lien extérieur dans une nouvelle fenêtre:<br/><br/>
          ${this.href}
          <br/><br/>
          Désirez vous continuer ?
        </div>
        <sl-button slot="bt1" close="true" variant="text">Annuler</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this.openLink}">Ouvrir le lien</sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('a-href-confirm', AHrefConfirm);