import { FullPageElement, html, css, formCss } from 'Elements';
import { UrlParams } from 'Utils';

class Toolbar extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        :host {
          margin-top:10px;
        }

        sl-badge {
          font-size:0.5em;
          position:absolute;
          cursor:pointer;
        }

        .events {
          display:flex;
          flex-wrap:wrap;
          gap:5px;
          padding:5px;
        }

        sl-option::part(base) {
          line-height:initial;
          padding:0px;
          font-size:0.95em;
        }

        sl-option m-icon {
          font-size:1.2em;
          float:left;
          margin-right:5px;
        }

        .search {
          display:flex;
          flex-direction:row;
          align-items:center;
          width:100%;
          gap:10px;
          margin-bottom:2px;
        }

        .search sl-input {
          width:100%;
        }

        .filters {
          height:40px;
          min-height:40px;
          overflow:hidden;
          transition: all 0.5s;
        }

        .filters.collapsed {
          height:0px;
          min-height:0px;
          transition: all 0.5s;
        }

        @media (max-width: 670px) {
          .header {
            flex-direction: column;
            gap: 10px;
          }
        }

      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            search_placeholder:'Search ...',
          }
        },
        french:{
          translation: {
            search_placeholder:'Rechercher ...',
          },
        }
      }
    ]
  }

  static get properties() {
    return {
      items: { type: Array },
      loading: { type: Boolean },
      groupBy: { type: String },
      newEvents: { type: Number },
    };
  }

  constructor() {
    super();
    this.groupBy='day';
    const events = UrlParams.get('events');
    this.period = UrlParams.get('dp');
    //this.displayFilter = this.period || (events && events.length) ? true : false;
    this.selectedEvents = events ? events.replace(',', ' ') : '';
    this.q = UrlParams.get('q');
  }
   
  onSearchInputDelayed(ev) {
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      this.q = this.qs('sl-input[name="q"]').value;
      UrlParams.set('q', this.q);
      UrlParams.del('p');
      UrlParams.del('ds');
      UrlParams.del('de');
      UrlParams.del('dp');
      this.reloadData();
    }, 500);
  }

  onSearchInput(ev) {
    this.q = this.qs('sl-input[name="q"]').value;
    UrlParams.set('q', this.q);
    UrlParams.del('p');
    UrlParams.del('ds');
    UrlParams.del('de');
    UrlParams.del('dp');
    this.reloadData();
  }


  onSelectEventsChange(ev) {
    this.selectedEvents = this.qs('sl-select[name="f"]').value.join(',');
    this.selectedEvents.length ? UrlParams.set('events', this.selectedEvents) : UrlParams.del('events');
    UrlParams.del('p');
    this.reloadData();
  }

  onPeriodChange(ev) {
    this.dateStart = ev.detail.start;
    this.dateEnd = ev.detail.end;
    this.period = ev.detail.value;
    this.dateStart ? UrlParams.set('ds', this.dateStart) : UrlParams.del('ds');
    this.dateEnd ? UrlParams.set('de', this.dateEnd) : UrlParams.del('de');
    this.period ? UrlParams.set('dp', this.period) : UrlParams.del('dp');
    this.reloadData();
  }

  changeGrouping(ev) {
    this.groupBy = this.parent.groupBy = ev.target.value;
  }

  reloadData() {
    this.parent.reloadData();
  }
  
  toggleFilters(ev) {
    const slotFilters = this.qs('.filters');
    if (slotFilters) {
      slotFilters.classList.toggle('collapsed');
    } else {
      console.error('filters not found');
    }

    this.displayFilter = !this.displayFilter;
    this.qs('m-icon[name="page_info"]').pressed = this.displayFilter;
  }

  firstUpdated() {
    super.firstUpdated();
    const hasFilters = this.qs('slot').assignedElements().length;
    const iconFilters = this.qs('m-icon[name="page_info"]');
    hasFilters ? iconFilters.style.display = 'block' : iconFilters.style.display = 'none';
    this.displayFilter = !hasFilters;
  }

  render() {    
    return html`
      <div class="search">
        <sl-input 
          size="small" 
          placeholder="${this._tl('search_placeholder')}" 
          name="q" 
          @sl-change=${this.onSearchInput}
          @sl-clear=${this.onSearchInput}
          clearable
          value="${this.q || ''}"
        >
          <m-icon name="search" slot="suffix"></m-icon>
        </sl-input>
        <select-date-periods value=${this.period} clearable @change="${this.onPeriodChange}"></select-date-periods>
        <data-periods-group @change="${this.changeGrouping}"></data-periods-group>
        <m-icon name="page_info" ?pressed=${this.displayFilter} variant="button" style="display:none" @click="${this.toggleFilters}"></m-icon>
      </div>
      <div class="filters ${this.displayFilter ? '': 'collapsed'}"><slot></slot></div>
    `;
  }
}

customElements.define('data-search-toolbar', Toolbar);
