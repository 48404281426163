import { LitElement, html, css } from 'lit-element';

class CookieConsent extends LitElement {
  static get styles() {
    return css`
      .cookie-consent {
        position: fixed;
        bottom: 10px;
        right: 20px;
        background-color: white;
        border: 1px solid #ccc;
        padding: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: none;
        color:black;
        font-size:12px;
        opacity:0.8;
        border-radius:5px;
      }

      .cookie-consent.active {
        display: block;
      }

      button {
        background-color: var(--nsys-blue4);
        color: white;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
      }
    `;
  }

  firstUpdated() {
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      this.shadowRoot.querySelector('.cookie-consent').classList.add('active');
    }
  }

  handleAccept() {
    localStorage.setItem('cookie-consent', 'accepted');
    this.shadowRoot.querySelector('.cookie-consent').classList.remove('active');
  }

  render() {
    return html`
      <div class="cookie-consent">
        <p>Nous utilisons un cookie pour<br/>suivre votre session dans l'application.</p>
        <button @click="${this.handleAccept}">Accepter</button>
      </div>
    `;
  }
}

customElements.define('cookie-consent', CookieConsent);