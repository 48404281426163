import BaseElement from './BaseElement.js';
import FullPageElement from './FullPageElement.js';
import PageElement from './PageElement.js';
import { directive, Directive, PartType } from 'lit/directive.js';

import { html, css, render } from 'lit-element';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { repeat } from 'lit/directives/repeat.js';
import { setAnimation } from '@shoelace-style/shoelace/dist/utilities/animation-registry.js';
import { getFormControls } from '@shoelace-style/shoelace/dist/utilities/form.js';
import formCss from './Form.css.js';
import '@lit-labs/virtualizer';


class HighlightDirective extends Directive {
  update(part, [text, query]) {
    return this.render(text, query);
  }

  render(text, query) {
    if (!query) {
      return text;
    }

    const regex = new RegExp(`(${query})`, 'gi');
    const fragments = text.split(regex);

    return fragments.map(part =>
      regex.test(part) ? html`<mark>${part}</mark>` : part
    );
  }
}

const highlight = directive(HighlightDirective);

export {
  FullPageElement,
  BaseElement,
  PageElement,
  html,
  css,
  render,
  highlight,
  unsafeHTML,
  setAnimation,
  getFormControls,
  formCss,
  repeat
};