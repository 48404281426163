import { LitElement, html, css } from 'lit-element';

class DebugScreenSize extends LitElement {
  static get properties() {
    return {
      screenWidth: { type: Number },
      screenHeight: { type: Number },
      deviceCategory: { type: String },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        padding: 2px;
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
        font-size:0.8em;
        color: white;
        position:fixed;
        bottom:0px;
        right:0px;
        background-color:#000000DD;
      }
    `;
  }

  constructor() {
    super();
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.deviceCategory = this.getDeviceCategory(this.screenWidth);
    this.updateScreenWidth = this.updateScreenWidth.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.updateScreenWidth);
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.updateScreenWidth);
    super.disconnectedCallback();
  }

  getDeviceCategory(width) {
    if (width <= 670) {
      return 'small mobile';
    } else if (width > 670 && width <= 900) {
      return 'big mobile or tablet';
    } else if (width > 900 && width <= 1024) {
      return 'tablet portrait';
    } else {
      return 'computer';
    }
  }

  updateScreenWidth() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
      this.deviceCategory = this.getDeviceCategory(this.screenWidth);
    }, 10);
  }

  render() {
    return html`
      ${this.screenWidth} x ${this.screenHeight}  - ${this.deviceCategory}
    `;
  }
}

customElements.define('debug-screen-size', DebugScreenSize);