import { css } from 'lit-element';
import FullPageElement from './FullPageElement.js';

class PageElement extends FullPageElement {

  static get properties() {
    return {
      ...super.properties,
      fullscreen: { type: Boolean }
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          display: block;
        }

        :host([fullscreen]) {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
        }

        /* iphone 8 */
        @media (max-width: 670px) {
          :host {
            padding-left: 5px;
            padding-right: 10px;
          }

          :host([fullscreen]) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      `
    ]
  }

  static get translations() {
    return [ super.translations ];
  }
  
  connectedCallback() {
    super.connectedCallback();
  }
}

export default PageElement;