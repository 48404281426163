import { LitElement, html, css } from 'lit-element';

class BoxStyled extends LitElement {
  static get styles() {
    return css`
      :host {
        display:block;
      }

      .main {
        display:flex;
        flex:1;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        text-align:center;
        min-height:80px;
        padding:5px;
        border-radius:3px;  
        cursor:pointer;
        gap:5px;
        border:1px solid transparent;
      }

      .main.row {
        flex-direction:row;
        justify-content:flex-start;
        text-align:left;
        min-height:45px;
      }

      .neuro {
        box-shadow: var(--neuromorph-box-shadow);
        transition: all 0.2s;
      }

      .neuro:hover {
        box-shadow: var(--neuromorph-box-shadow-hover);
        color:var(--sl-color-primary-600);
        /*
        border-bottom:1px solid var(--sl-color-primary-500);
        border-right:1px solid var(--sl-color-primary-500);
        */
        background-color:var(--sl-color-primary-100);
      }

      .neuro.nobgchange:hover {
        background-color:transparent;
      }

      .basic {
        /*color:var(--nsys-blue3);*/
        outline:1px solid var(--sl-color-neutral-300);
      }

      .basic:hover {
        color:var(--sl-color-primary-600);
        border:1px solid var(--sl-color-primary-500);
        background-color:var(--sl-color-primary-100);
      }

      .warning {
        background-color:var(--sl-color-warning-100);
        min-height:initial;
        padding-top:2px;
        padding-bottom:2px;
        padding-left:10px;
        border:1px solid var(--sl-color-warning-200);
      }

      .container {
        outline:1px solid var(--sl-color-neutral-300);
        cursor:initial;
        display:block;
        text-align:initial;
        min-height:initial;
      }

      .warning m-icon {
        font-size:1.5em;
      }

      .warning.main.row {
        min-height:initial;
      }

      .info {
        background-color:var(--sl-color-primary-100);
        min-height:initial;
        padding-top:2px;
        padding-bottom:2px;
        padding-left:10px;
        font-size:0.9em;
        border:1px solid var(--sl-color-primary-200);
      }

      .info m-icon {
        font-size:1.5em;
      }

      .info.main.row {
        min-height:initial;
      }


      ::slotted([slot="icon"]) {
        font-size:2.5em;
        font-weight:200;
      }

      m-icon {
        font-size:2em;
      }

      a {
        text-decoration:none;
        color:inherit;
      }

      /* Téléphones portables (petits appareils) - iPhone 8 et similaires */
      @media (max-width: 670px) {
        :host {
          font-size:0.9em;
        }
      }

 
    `;
  }

  static get properties() {
    return {
      variant: { type: String },
      layout: { type: String },
      micon: { type: String },
      img: { type: String },
      aroute: { type: String },
      href: { type: String },
      target: { type: String },
      height: { type: String },
      hoverNoBackgroundChange: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.variant = 'basic';
    this.layout = '';
    this.micon = '';
    this.img = '';
    this.aroute = '';
    this.href = '';
    this.target = '';
    this.height = '';
    this.hoverNoBackgroundChange = false;
  }

  render() {

    if (this.variant === 'warning') {
      this.layout = 'row';
      this.micon = 'warning';
    } else if (this.variant === 'info') {
      this.layout = 'row';
      this.micon = 'info';
    }

    const icon = this.micon ? html`<m-icon name="${this.micon}"></m-icon>` : '';
    const img = this.img ? html`<img src="${this.img}" style="height: 45px;"></img>` : '';

    // Appliquer la hauteur si elle est spécifiée
    const mainStyle = this.height ? `height: ${this.height};` : '';

    const content = html`
      <div class="main ${this.variant} ${this.layout} ${this.hoverNoBackgroundChange ? 'nobgchange' : ''}" style="${mainStyle}">
        <slot name="icon">${icon}</slot>
        <slot name="img">${img}</slot>
        <slot></slot>
      </div>
    `;

    if (this.aroute) {
      return html`<a-route href="${this.aroute}">${content}</a-route>`;
    }

    if (this.href) {
      return html`<a target="${this.target}" href="${this.href}">${content}</a>`;
    }

    return content;
  }
}

customElements.define('box-styled', BoxStyled);

