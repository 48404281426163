import { BaseElement, html, css } from 'Elements';

class GroupPeriod extends BaseElement {
  static get styles() {
    return [
      css`
        .flex {
          display:flex;
          gap:5px;
        }
      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            day: 'Day',
            week: 'Week',
            month: 'Month',
            group_by: 'Group by'
          }
        },
        french:{
          translation: {
            day: 'Jour',
            week: 'Semaine',
            month: 'Mois',
            group_by: 'Regrouper par'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.value = 'day';
  }

  change(e) {
    this.value = e.target.getAttribute('value');
    // emit event 
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  render() {
    return html`
      <div class="flex">
        
        <m-icon
          name="calendar_view_day"
          value="day"
          variant="button"
          ?pressed=${this.value === 'day'}
          title="${this._tl('group_by')} ${this._tl('day')}"
          @click=${this.change}
        ></m-icon>

        <m-icon
          name="calendar_view_week"
          value="week"
          variant="button"
          ?pressed=${this.value === 'week'}
          title="${this._tl('group_by')} ${this._tl('week')}"
          @click=${this.change}
        ></m-icon>

        <m-icon
          name="calendar_view_month"
          value="month"
          variant="button"
          ?pressed=${this.value === 'month'}
          title="${this._tl('group_by')} ${this._tl('month')}"
          @click=${this.change}
        ></m-icon>

      </div>
    `;
  }
}

customElements.define('data-periods-group', GroupPeriod);
