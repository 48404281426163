import { LitElement, html, css } from 'lit-element';

class BoxPre extends LitElement {
  static get styles() {
    return css`
      pre {
        font-size:0.85em;
        background-color:var(--sl-color-neutral-0);
        padding:5px;
        margin:5px;
      }
    `;
  }

  static get properties() {
    return {
    };
  }

  constructor() {
    super();
  }

  render() {

    return html`
      <pre><slot></slot></pre>
    `;
  }
}

customElements.define('box-pre', BoxPre);

