import { html, css, BaseElement } from 'Elements';

import './PrettyJson.js';

class BoxJson extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display: block;
          width: 100%;
        }

        .cont {
          padding:5px;
          background-color:var(--sl-panel-background-color);
          overflow-y: scroll;
        }

        scrollable-component {
          max-height:calc(100vh - 270px);
          --scrollbar-width: 10px;
          --content-padding: 0 20px 0 0;
          --viewport-overflow-x: hidden;
        }
      `
    ]
  }

  static get properties() {
    return {
      expand: { type: String },
      value: { type: Object }
    };
  }

  constructor() {
    super();
    this.expand = 4;
    this.value = {};
  }

  render() {
    if (!this.value) return;

    let style = '';
    style+='font-size:0.85em !important;';

    return html`
      <div class="cont">
        <scrollable-component scrollbar-visibility="always">
        <style>.container { font-size:initial; }</style>
        <pretty-json 
          expand="${this.expand}"
          style="${style}"
          .data=${this.value}
        ></pretty-json>
        </scrollable-component>
      </div>
    `;
  }
}

customElements.define('box-json', BoxJson);


