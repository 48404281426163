import { LitElement, html, css } from 'lit-element';
import { Router } from 'Router';

class ARoute extends LitElement {
  static get styles() {
    return css`
      a {
        color: inherit;
        text-decoration: none;
      }

      a:hover {
        color: var(--sl-color-primary-500);
      }
    `
  }

  static get properties() {
    return {
      href: { type: String },
      title: { type: String, reflect: true }
    };
  }

  constructor() {
    super();
    this.href = '';
    this.title = '';
  }

  getHref() {
    const baseUrl = window.location.origin + window.location.pathname+'/';
    let href = new URL(this.href, baseUrl).href;
    href = href.replace(/\/$/, '');
    return href;
  }

  navigate(ev) {
    ev.preventDefault();

    if (ev.ctrlKey) {
      const win = window.open(this._href, '_blank');
      if (win) {
        win.focus();
      }
      return;
    }

    Router.go(this._href);
    window.scrollTo(0, 0);
  }

  render() {
    this._href = this.getHref();
    return html`
      <a href="${this._href}" @click="${this.navigate}" aria-label="${this.title}">
        <slot part="text"></slot>
      </a>
    `;
  }
}

customElements.define('a-route', ARoute);