import { BaseElement, html, css } from 'Elements';

class DataPaginator extends BaseElement {
  static get styles() {
    return [
      css`
        .flex {
          display: flex;
          gap: 0px;
        }

        .flex > * {
        }

        .flex sl-button::part(base) {
          font-size: 0.8em;
          min-width:25px;
          padding: 0px;
        }

        .flex sl-button::part(label) {
          padding: 0px;
        }

        .flex sl-button::part(prefix) {
          display:none;
        }

        .flex sl-button::part(suffix) {
          display:none;
        }

        .flex m-icon {
          padding-top: 5px;
          font-size:1.3em;
          color:var(--sl-color-primary-500);
        }

        .active {
          font-weight: bold;
          background-color: #d3d3d3;
        }
      `,
    ];
  }

  static get properties() {
    return {
      value: { type: Number },
      pages: { type: Number },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            first: 'First page',
            last: 'Last page',
            previous: 'Previous page',
            next: 'Next page',
          },
        },
        french: {
          translation: {
            first: 'Première page',
            last: 'Dernière page',
            previous: 'Page précédente',
            next: 'Page suivante',
          },
        },
      },
    ];
  }

  constructor() {
    super();
    this.value = 1; // Page par défaut
    this.pages = 1; // Nombre de pages par défaut
  }

  change(e) {
    const pageValue = e.target.getAttribute('value');
    if (pageValue === 'first') {
      this.value = 1;
    } else if (pageValue === 'last') {
      this.value = this.pages;
    } else if (pageValue === 'previous') {
      this.value = Math.max(1, this.value - 1);
    } else if (pageValue === 'next') {
      this.value = Math.min(this.pages, this.value + 1);
    } else {
      this.value = parseInt(pageValue, 10);
    }
    // Emit event
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  render() {
    // Définir une plage de pages dynamiques autour de la page active
    const maxPagesToShow = 5;
    const halfRange = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, this.value - halfRange);
    let endPage = Math.min(this.pages, this.value + halfRange);

    // Ajuster si la page courante est proche du début ou de la fin
    if (this.value <= halfRange) {
      endPage = Math.min(this.pages, maxPagesToShow);
    } else if (this.value > this.pages - halfRange) {
      startPage = Math.max(1, this.pages - maxPagesToShow + 1);
    }

    if (startPage === endPage) {
      return html``;
    }

    return html`
      <div class="flex">
        <m-icon clickable @click=${this.change} value="first" name="first_page"></m-icon>
        <m-icon clickable @click=${this.change} value="previous" name="chevron_left"></m-icon>

        ${Array.from({ length: endPage - startPage + 1 }, (_, index) => {
          const pageNumber = startPage + index;
          return html`
            <sl-button
              size="small"
              variant="${this.value === pageNumber ? 'primary' : 'text'}"
              @click=${this.change}
              value="${pageNumber}"
            >
              ${pageNumber}
            </sl-button>
          `;
        })}

        <m-icon clickable @click=${this.change} value="next" name="chevron_right"></m-icon>
        <m-icon clickable @click=${this.change} value="last" name="last_page"></m-icon>
      </div>
    `;
  }
}

customElements.define('data-paginator', DataPaginator);