import { LitElement, html, css } from 'lit-element';

class AlertMessage extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        padding: 16px;
        margin-bottom: 16px;
      }

      .title {
        margin-bottom: 8px;
        font-size: 15px;
      }

      .message {
        font-size: 13px;
      }

      .url {
        margin-top: 8px;
        font-size: 12px;
        opacity:0.5;
      }
    `;
  }

  static get properties() {
    return {
      title: { type: String },
      message: { type: String },
      url: { type: String },
      json: { type: Object }
    };
  }

  constructor() {
    super();
    this.title = '';
    this.message = '';
    this.url = '';
    this.json = null;
  }

  render() {
    console.log(this.json);
    return html`
      ${this.message
        ? html`
            <sl-details summary="${this.title}">
              <div class="message">${this.message}</div>
              ${this.json
                ? `<box-pre>${this.json}</box-pre>`
                : ''
              }
              <div class="url">${this.url}</div>
            </sl-details>
          `
        : html`<div class="title">${this.title}</div>`
      }
    `;
  }
}

customElements.define('alert-message', AlertMessage);