import { css } from 'lit-element';
import BaseElement from './BaseElement.js';
import pageStyles from './pageStyles.js';
import { Session } from 'Utils';

class FullPageElement extends BaseElement {
  static get properties() {
    return {
      _lang: { type: String }
    }
  }

  static get styles() {
    return [
      pageStyles,
      css`
        :host {
          display:block;
        }
      `
    ]
  }

  static get translations() {
    return [ super.translations ];
  }

  firstUpdated() {
    super.firstUpdated();
    this.initLayout();
    // @TODO: wait a little, but this should be dropped
    //this._pagePagePaddingTop();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.restoreLayout();
  }

  initLayout() {
    this.previousLayout = document.documentElement.classList.contains('layout-large') ? 'large' : 'center';

    if (this.layout) {
      if (this.layout === 'center') {
        document.documentElement.classList.remove('layout-large');
      } else {
        document.documentElement.classList.add('layout-large');
      }
    }
  }

  restoreLayout() {
    if (this.layout) {
      if (this.previousLayout === 'center') {
        document.documentElement.classList.remove('layout-large');
      } else {
        document.documentElement.classList.add('layout-large');
      }
    }
  }

  /*
  _fixPagePaddingTop() {
    
    let el = this.shadowRoot.querySelector('div.content_page');
    if (el) {
      el.classList.add('collapsed');
      //console.log('fix', el);
    }

    el = this.shadowRoot.querySelector('div.content_large');
    if (el) {
      el.classList.add('collapsed');
      //console.log('fix', el);
    }
  }

  _pagePagePaddingTop() {
    this._fixPagePaddingTop();
    //clearTimeout(this._timerScroll);
    //this._timerScroll = setTimeout(() => { this._fixPagePaddingTop(); }, 1);
  }
  */

}

export default FullPageElement;